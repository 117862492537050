<template>
  <div>
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div class="card">
          <div class="card-body">
            <div class="mb-3">Way of recharge</div>
            <div>
              <button class="btn btn-info btn-block">ETHEREUM</button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="text-danger">*Recharge Tip</div>
            <div class="text-danger">
              <h3 class="text-danger">The minimum recharge amount is 9</h3>
              <p>If your recharge amount is less than the minimum amount, recharge will be refused and refunding will be
                unavailable</p>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="mb-3">QR code</div>
            <div class="">
              <img src="@/assets/images/qr_code.png" class="img-fluid" alt="">
              <button class="btn btn-info btn-block btn-sm mt-3" id="copy"
                      @click="copyAddress('0xE988a3c986e78Fce709d9ab8AE65CB8696De68bE')">Copy Address
              </button>
            </div>

            <form @submit.prevent="submitDepositRequest" class="mt-4">
              <label for="amount" class="">Amount</label>
              <input type="number" min="9" v-model="data.amount" required id="amount" class="form-control">

              <label for="address" class="mt-3">Your Deposit Address</label>
              <input type="text" v-model="data.address" id="address" required class="form-control">

              <button type="submit" class="btn btn-success btn-block mt-3">Submit Request
              </button>
            </form>
          </div>
        </div>

      </div>
      <!-- end col -->
    </div>

    <!-- end row -->
    <mobilefooter/>

  </div>
</template>

<script>


export default {
  name: "Recharge",
  components: {
    mobilefooter: () => import("@/components/MobileFooter")

  },
  data() {
    return {
      loader: false,
      data: {
        amount: '9',
        address: ''
      }
    }
  },
  methods: {
    makeToast(heading,title,variant = 'success') {
      this.$bvToast.toast(title, {
        title: `${heading}`,
        variant: variant,
        autoHideDelay: 3000,
        solid: true
      })
    },
    copyAddress(address) {
      navigator.clipboard.writeText(address).then(() => {
        this.makeToast("Success","Address Copied!")
      });
    },
    submitDepositRequest() {
      this.$store.dispatch('deposit', this.data).then((res) => {
        this.data.address='';
        this.data.amount='9';
      })
    },

  }

}
</script>

<style scoped>

</style>